import {Add} from '@mui/icons-material'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Box, Button, Typography} from '@mui/material'
import React, {useState} from 'react'
import {FieldValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {TfaData} from '../../../pages/ManageUser/hooks/useTfaData'
import {TfaDialog} from '../dialogs/TfaDialog'
import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'

import {useStyles} from './TfaInput.styles'
import {UserInformationProps} from './types'

type TfaInputProps<T extends FieldValues> = UserInformationProps<T> & {
  twoFactorEnabled?: boolean
  tfaData?: TfaData
}

export const TfaInput = <T extends FieldValues>({
  formMethods,
  isLoading,
  tfaData
}: TfaInputProps<T>) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const [showTfaDialog, setShowTfaDialog] = useState(false)

  const mobileNumber = formMethods.getValues().mobileNumber

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  return (
    <Box style={{marginTop: '20px'}}>
      <Typography variant="h5" style={{color: '#54708C'}}>
        {t('userInformationForm.tfa.title')}
      </Typography>
      {(!tfaData?.isTfaEnabled || tfaData?.tfaType === 'none') && (
        <Button
          variant="text"
          color="primary"
          onClick={() => setShowTfaDialog(true)}
          className={classes.addSecurityMethod}
          data-test-id="button-add-new-security-method"
        >
          <Add className={classes.icon} style={{color: '#016AD4', fontSize: '14px'}} />
          {t('userInformationForm.tfa.addSecurity')}
        </Button>
      )}
      {tfaData?.isTfaEnabled && tfaData?.tfaType !== 'none' && (
        <Button
          variant="text"
          color="primary"
          onClick={() => setShowTfaDialog(true)}
          className={classes.addSecurityMethod}
          data-test-id="button-edit-security-method"
        >
          <ModeEditOutlineIcon
            className={classes.icon}
            style={{color: '#016AD4', fontSize: '14px'}}
          />
          {t('userInformationForm.tfa.editSecurity')}
        </Button>
      )}
      {showTfaDialog && (
        <TfaDialog
          setShowTfaDialog={setShowTfaDialog}
          showTfaDialog={showTfaDialog}
          tfaType={tfaData?.tfaType || 'none'}
          mobileNumber={mobileNumber}
          edit={!!tfaData?.isTfaEnabled}
        />
      )}
    </Box>
  )
}
